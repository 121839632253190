import React from 'react'
import BigFooter from '../components/BigFooter'
import ButtonUp from '../components/ButtonUp'
import MainHeader from '../components/MainHeader'
import MobileMenu from '../components/MobileMenu'
import Newsletter from '../components/Newsletter'
import ProductBody from '../components/ProductBody'

const Product = () => {
  return (
    <div>
      <MainHeader />
      <ProductBody />
      <BigFooter />
      <ButtonUp />
      <MobileMenu />
      <Newsletter />
    </div>
  )
}

export default Product
