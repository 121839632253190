import React from 'react'
import Homepage from './layouts/Homepage'
import Checkout from './layouts/Checkout'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Category from './layouts/Category'
import Comingsoon from './layouts/Comingsoon'
import About from './layouts/About'
import FAQ from './layouts/FAQ'
import Cart from './layouts/Cart'
import Login from './layouts/Login'
import Contact from './layouts/Contact'
import SigninModal from './components/SigninModal'
import ErrorPage from './layouts/ErrorPage'
import Product from './layouts/Product'
import Wishlist from './layouts/Wishlist'

function App() {
  return (
    <div className='App'>
      {/* <Comingsoon /> */}
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Homepage />} />
          <Route path='/index' exact element={<Homepage />} />
          <Route path='/checkout' exact element={<Checkout />} />
          <Route path='/category' exact element={<Category />} />
          <Route path='/about' exact element={<About />} />
          <Route path='/faq' exact element={<FAQ />} />
          <Route path='/cart' exact element={<Cart />} />
          <Route path='/login' exact element={<Login />} />
          <Route path='/contact' exact element={<Contact />} />
          <Route path='/product' exact element={<Product />} />
          <Route path='/wishlist' exact element={<Wishlist />} />
          <Route path='/coming-soon' exact element={<Comingsoon />} />
          <Route path='*' element={<ErrorPage />} />
        </Routes>
      </BrowserRouter>
      {/* Required on every page */}
      <SigninModal />
    </div>
  )
}

export default App
