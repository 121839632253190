import React from 'react'
import { Link } from 'react-router-dom'

const MainHeader = () => {
  return (
    <div>
      <header className='header'>
        <div className='header-top'>
          <div className='container'>
            <div className='header-left'>
              <div className='header-dropdown'>
                <a href='/'>GHS</a>
                <div className='header-menu'>
                  <ul>
                    <li>
                      <a href='/'>USD</a>
                    </li>
                    <li>
                      <a href='/'>GHS</a>
                    </li>
                  </ul>
                </div>
                {/* End .header-menu */}
              </div>
              {/* End .header-dropdown */}
              <div className='header-dropdown'>
                <a href='/'>Eng</a>
                <div className='header-menu'>
                  <ul>
                    <li>
                      <a href='/'>English</a>
                    </li>
                    <li>
                      <a href='/'>French</a>
                    </li>
                  </ul>
                </div>
                {/* End .header-menu */}
              </div>
              {/* End .header-dropdown */}
            </div>
            {/* End .header-left */}
            <div className='header-right'>
              <ul className='top-menu'>
                <li>
                  <a href='/'>Links</a>
                  <ul>
                    <li>
                      <a href='tel:#'>
                        <i className='icon-phone' />
                        Call: (+233)554463424
                      </a>
                    </li>
                    <li>
                      <Link to='/wishlist'>
                        <i className='icon-heart-o' />
                        Wishlist <span>(3)</span>
                      </Link>
                    </li>
                    <li>
                      <Link to='/about'>About Us</Link>
                    </li>
                    <li>
                      <Link to='/contact'>Contact Us</Link>
                    </li>
                    <li>
                      <a href='#signin-modal' data-toggle='modal'>
                        <i className='icon-user' />
                        Login
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
              {/* End .top-menu */}
            </div>
            {/* End .header-right */}
          </div>
          {/* End .container */}
        </div>
        {/* End .header-top */}
        <div className='header-middle sticky-header'>
          <div className='container'>
            <div className='header-left'>
              <button className='mobile-menu-toggler'>
                <span className='sr-only'>Toggle mobile menu</span>
                <i className='icon-bars' />
              </button>
              <a href='/' className='logo'>
                Annie Sawyer
                {/* <img
                  src='assets/images/logo.png'
                  alt='Molla Logo'
                  width={105}
                  height={25}
                /> */}
              </a>
              <nav className='main-nav'>
                <ul className='menu sf-arrows'>
                  <li>
                    <Link to='/category' className='sf-with-ul'>
                      Categories
                    </Link>
                    <div className='megamenu megamenu-sm'>
                      <div className='row no-gutters'>
                        <div className='col-md-6'>
                          <div className='menu-col'>
                            <div className='menu-title'>Product Categories</div>
                            {/* End .menu-title */}
                            <ul>
                              <li>
                                <Link to='/category'>New Arrivals</Link>
                              </li>
                              <li>
                                <Link to='/category'>Sales &amp; Deals</Link>
                              </li>
                              <li>
                                <Link to='/category'>
                                  Sales &amp; Deals
                                  <span>
                                    Extended Info
                                    <span className='tip tip-new'>Brands</span>
                                  </span>
                                </Link>
                              </li>
                            </ul>
                          </div>
                          {/* End .menu-col */}
                        </div>
                        {/* End .col-md-6 */}
                        <div className='col-md-6'>
                          <div className='banner banner-overlay'>
                            <Link to='/category'>
                              <img
                                src='assets/images/menu/banner-2.jpg'
                                alt='Banner'
                              />
                              <div className='banner-content banner-content-bottom'>
                                <div className='banner-title text-white'>
                                  New Trends
                                  <br />
                                  <span>
                                    <strong>spring 2019</strong>
                                  </span>
                                </div>
                                {/* End .banner-title */}
                              </div>
                              {/* End .banner-content */}
                            </Link>
                          </div>
                          {/* End .banner */}
                        </div>
                        {/* End .col-md-6 */}
                      </div>
                      {/* End .row */}
                    </div>
                    {/* End .megamenu megamenu-sm */}
                  </li>
                  <li>
                    <Link to='#' className='sf-with-ul'>
                      Pages
                    </Link>
                    <ul>
                      <li>
                        <Link to='/about'>About</Link>
                      </li>
                      <li>
                        <Link to='/contact'>Contact</Link>
                      </li>
                      <li>
                        <Link to='/login'>Login</Link>
                      </li>
                      <li>
                        <Link to='/faq'>FAQs</Link>
                      </li>
                      <li>
                        <Link to='/coming-soon'>Coming Soon</Link>
                      </li>
                    </ul>
                  </li>
                </ul>
                {/* End .menu */}
              </nav>
              {/* End .main-nav */}
            </div>
            {/* End .header-left */}
            <div className='header-right'>
              <div className='header-search'>
                <a
                  href='/'
                  className='search-toggle'
                  role='button'
                  title='Search'
                >
                  <i className='icon-search' />
                </a>
                <form action='#' method='get'>
                  <div className='header-search-wrapper'>
                    <label htmlFor='q' className='sr-only'>
                      Search
                    </label>
                    <input
                      type='search'
                      className='form-control'
                      name='q'
                      id='q'
                      placeholder='Search in...'
                      required
                    />
                  </div>
                  {/* End .header-search-wrapper */}
                </form>
              </div>
              {/* End .header-search */}

              {/* End .compare-dropdown */}
              <div className='dropdown cart-dropdown'>
                <a
                  href='/'
                  className='dropdown-toggle'
                  role='button'
                  data-toggle='dropdown'
                  aria-haspopup='true'
                  aria-expanded='false'
                  data-display='static'
                >
                  <i className='icon-shopping-cart' />
                  <span className='cart-count'>2</span>
                </a>
                <div className='dropdown-menu dropdown-menu-right'>
                  <div className='dropdown-cart-products'>
                    <div className='product'>
                      <div className='product-cart-details'>
                        <h4 className='product-title'>
                          <Link to='/product'>
                            Beige knitted elastic runner shoes
                          </Link>
                        </h4>
                        <span className='cart-product-info'>
                          <span className='cart-product-qty'>1</span>x $84.00
                        </span>
                      </div>
                      {/* End .product-cart-details */}
                      <figure className='product-image-container'>
                        <Link to='/product' className='product-image'>
                          <img
                            src='assets/images/products/cart/product-1.jpg'
                            alt='product'
                          />
                        </Link>
                      </figure>
                      <a href='/' className='btn-remove' title='Remove Product'>
                        <i className='icon-close' />
                      </a>
                    </div>
                    {/* End .product */}
                    <div className='product'>
                      <div className='product-cart-details'>
                        <h4 className='product-title'>
                          <Link to='/product'>
                            Blue utility pinafore denim dress
                          </Link>
                        </h4>
                        <span className='cart-product-info'>
                          <span className='cart-product-qty'>1</span>x $76.00
                        </span>
                      </div>
                      {/* End .product-cart-details */}
                      <figure className='product-image-container'>
                        <Link to='/product' className='product-image'>
                          <img
                            src='assets/images/products/cart/product-2.jpg'
                            alt='product'
                          />
                        </Link>
                      </figure>
                      <a href='/' className='btn-remove' title='Remove Product'>
                        <i className='icon-close' />
                      </a>
                    </div>
                    {/* End .product */}
                  </div>
                  {/* End .cart-product */}
                  <div className='dropdown-cart-total'>
                    <span>Total</span>
                    <span className='cart-total-price'>$160.00</span>
                  </div>
                  {/* End .dropdown-cart-total */}
                  <div className='dropdown-cart-action'>
                    <Link to='/cart' className='btn btn-primary'>
                      View Cart
                    </Link>
                    <Link to='/checkout' className='btn btn-outline-primary-2'>
                      <span>Checkout</span>
                      <i className='icon-long-arrow-right' />
                    </Link>
                  </div>
                  {/* End .dropdown-cart-total */}
                </div>
                {/* End .dropdown-menu */}
              </div>
              {/* End .cart-dropdown */}
            </div>
            {/* End .header-right */}
          </div>
          {/* End .container */}
        </div>
        {/* End .header-middle */}
      </header>
      {/* End .header */}
    </div>
  )
}

export default MainHeader
