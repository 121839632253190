import React from 'react'

const ContactBody = () => {
  const [contactUsForm, setcontactUsForm] = React.useState({
    name: '',
    email: '',
    phone: '',
    subject: '',
    message: '',
  })
  return (
    <>
      <main className='main'>
        <nav aria-label='breadcrumb' className='breadcrumb-nav border-0 mb-0'>
          <div className='container'>
            <ol className='breadcrumb'>
              <li className='breadcrumb-item'>
                <a href='/index'>Home</a>
              </li>
              <li className='breadcrumb-item'>
                <a href='#'>Pages</a>
              </li>
              <li className='breadcrumb-item active' aria-current='page'>
                Contact us
              </li>
            </ol>
          </div>
          {/* End .container */}
        </nav>
        {/* End .breadcrumb-nav */}
        <div className='container'>
          <div
            className='page-header page-header-big text-center'
            style={{
              backgroundImage: 'url("assets/images/contact-header-bg.jpg")',
            }}
          >
            <h1 className='page-title text-white'>
              Contact us
              <span className='text-white'>keep in touch with us</span>
            </h1>
          </div>
          {/* End .page-header */}
        </div>
        {/* End .container */}
        <div className='page-content pb-0'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-6 mb-2 mb-lg-0'>
                <h2 className='title mb-1'>Contact Information</h2>
                {/* End .title mb-2 */}
                <p className='mb-3'>
                  Feel free to contact us at any time. We are more than happy to
                  help
                </p>
                <div className='row'>
                  <div className='col-sm-7'>
                    <div className='contact-info'>
                      <h3>The Office</h3>
                      <ul className='contact-list'>
                        <li>
                          <i className='icon-map-marker' />
                          Accra-Ghana
                        </li>
                        <li>
                          <i className='icon-phone' />
                          <a href='tel:#'>+ 233 20 661 6627</a>
                        </li>
                        <li>
                          <i className='icon-phone' />
                          <a href='tel:#'>+ 233 20 885 9329</a>
                        </li>
                        <li>
                          <i className='icon-envelope' />
                          <a href='mailto:#'>annie@anniesawyer.com</a>
                        </li>
                      </ul>
                      {/* End .contact-list */}
                    </div>
                    {/* End .contact-info */}
                  </div>
                  {/* End .col-sm-7 */}
                  <div className='col-sm-5'>
                    <div className='contact-info'>
                      <h3>The Office</h3>
                      <ul className='contact-list'>
                        <li>
                          <i className='icon-clock-o' />
                          <span className='text-dark'>
                            Monday-Saturday
                          </span>{' '}
                          <br />
                          8am-6pm GMT
                        </li>
                        <li>
                          <i className='icon-calendar' />
                          <span className='text-dark'>Sunday</span> <br />
                          11am-6pm GMT
                        </li>
                      </ul>
                      {/* End .contact-list */}
                    </div>
                    {/* End .contact-info */}
                  </div>
                  {/* End .col-sm-5 */}
                </div>
                {/* End .row */}
              </div>
              {/* End .col-lg-6 */}
              <div className='col-lg-6'>
                <h2 className='title mb-1'>Got Any Questions?</h2>
                {/* End .title mb-2 */}
                <p className='mb-2'>
                  Use the form below to get in touch with the sales team
                </p>
                <form action='#' className='contact-form mb-3'>
                  <div className='row'>
                    <div className='col-sm-6'>
                      <label htmlFor='cname' className='sr-only'>
                        Name
                      </label>
                      <input
                        type='text'
                        className='form-control'
                        id='cname'
                        placeholder='Name *'
                        required=''
                        value={contactUsForm.name}
                        onChange={(e) =>
                          setcontactUsForm({
                            ...contactUsForm,
                            name: e.target.value,
                          })
                        }
                      />
                    </div>
                    {/* End .col-sm-6 */}
                    <div className='col-sm-6'>
                      <label htmlFor='cemail' className='sr-only'>
                        Email
                      </label>
                      <input
                        type='email'
                        className='form-control'
                        id='cemail'
                        placeholder='Email *'
                        required=''
                        value={contactUsForm.email}
                        onChange={(e) =>
                          setcontactUsForm({
                            ...contactUsForm,
                            email: e.target.value,
                          })
                        }
                      />
                    </div>
                    {/* End .col-sm-6 */}
                  </div>
                  {/* End .row */}
                  <div className='row'>
                    <div className='col-sm-6'>
                      <label htmlFor='cphone' className='sr-only'>
                        Phone
                      </label>
                      <input
                        type='tel'
                        className='form-control'
                        id='cphone'
                        placeholder='Phone'
                        value={contactUsForm.phone}
                        onChange={(e) =>
                          setcontactUsForm({
                            ...contactUsForm,
                            phone: e.target.value,
                          })
                        }
                      />
                    </div>
                    {/* End .col-sm-6 */}
                    <div className='col-sm-6'>
                      <label htmlFor='csubject' className='sr-only'>
                        Subject
                      </label>
                      <input
                        type='text'
                        className='form-control'
                        id='csubject'
                        placeholder='Subject'
                        value={contactUsForm.subject}
                        onChange={(e) =>
                          setcontactUsForm({
                            ...contactUsForm,
                            subject: e.target.value,
                          })
                        }
                      />
                    </div>
                    {/* End .col-sm-6 */}
                  </div>
                  {/* End .row */}
                  <label htmlFor='cmessage' className='sr-only'>
                    Message
                  </label>
                  <textarea
                    className='form-control'
                    cols={30}
                    rows={4}
                    id='cmessage'
                    required=''
                    placeholder='Message *'
                    value={contactUsForm.message}
                    onChange={(e) =>
                      setcontactUsForm({
                        ...contactUsForm,
                        message: e.target.value,
                      })
                    }
                  />
                  <button
                    type='submit'
                    className='btn btn-outline-primary-2 btn-minwidth-sm'
                    onClick={() => console.log(contactUsForm)}
                  >
                    <span>SUBMIT</span>
                    <i className='icon-long-arrow-right' />
                  </button>
                </form>
                {/* End .contact-form */}
              </div>
              {/* End .col-lg-6 */}
            </div>
            {/* End .row */}
            <hr className='mt-4 mb-5' />

            {/* End .stores */}
          </div>
          {/* End .container */}
          <div id='map' />
          {/* End #map */}
        </div>
        {/* End .page-content */}
      </main>
      {/* End .main */}
    </>
  )
}

export default ContactBody
