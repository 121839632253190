import React from 'react'

const ButtonUp = () => {
  return (
    <div>
      <button id='scroll-top' title='Back to Top'>
        <i className='icon-arrow-up' />
      </button>
    </div>
  )
}

export default ButtonUp
