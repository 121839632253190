import React, { useEffect } from 'react'
import ButtonUp from '../components/ButtonUp'
import Footer from '../components/Footer'
import Header from '../components/Header'
import HomeBody from '../components/HomeBody'
import MobileMenu from '../components/MobileMenu'
import Newsletter from '../components/Newsletter'
import QuickViewHome from '../components/QuickViewHome'

const Homepage = () => {
  const [showNewsLetter, setShowNewsLetter] = React.useState(true)
  const [show, setShow] = React.useState(false)

  useEffect(() => {
    // window.location.reload()
  }, [])

  const handleClick = () => {
    console.log(showNewsLetter)
    setShowNewsLetter(!showNewsLetter)
  }
  return (
    <>
      {show ? <QuickViewHome setShow={setShow} /> : null}
      <div className='page-wrapper'>
        <Header />
        <HomeBody show={show} setShow={setShow} />
        <Footer />
        <ButtonUp />
        <MobileMenu />
        {showNewsLetter && <Newsletter handleClick={handleClick} />}
      </div>
    </>
  )
}

export default Homepage
