import React from 'react'
import CategoryBody from '../components/CategoryBody'
import BigFooter from '../components/BigFooter'
import MainHeader from '../components/MainHeader'
import MobileMenu from '../components/MobileMenu'
import Newsletter from '../components/Newsletter'
import ButtonUp from '../components/ButtonUp'
import QuickView from '../components/QuickView'

const Category = () => {
  const [show, setShow] = React.useState(false)
  return (
    <>
      {show ? <QuickView setShow={setShow} /> : null}
      <MainHeader />
      <CategoryBody show={show} setShow={setShow} />
      <BigFooter />
      <ButtonUp />
      <MobileMenu />
      <Newsletter />
    </>
  )
}

export default Category
