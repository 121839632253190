import React from 'react'
import BigFooter from '../components/BigFooter'
import MainHeader from '../components/MainHeader'
import MobileMenu from '../components/MobileMenu'
import Newsletter from '../components/Newsletter'
import ButtonUp from '../components/ButtonUp'
import LoginBody from '../components/LoginBody'

const Login = () => {
  return (
    <div className='page-wrapper'>
      <MainHeader />
      <LoginBody />
      <BigFooter />
      <ButtonUp />
      <MobileMenu />
      <Newsletter />
    </div>
  )
}

export default Login
