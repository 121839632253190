import React from 'react'
import BigFooter from '../components/BigFooter'
import MainHeader from '../components/MainHeader'
import MobileMenu from '../components/MobileMenu'
import Newsletter from '../components/Newsletter'
import ButtonUp from '../components/ButtonUp'
import AboutBody from '../components/AboutBody'

const About = () => {
  return (
    <>
      <div className='page-wrapper'>
        <MainHeader />
        <AboutBody />
        <BigFooter />
        <ButtonUp />
        <MobileMenu />
        <Newsletter />
      </div>
      {/* End .page-wrapper */}
    </>
  )
}

export default About
