import React from 'react'
import { Link } from 'react-router-dom'
const HomeBody = ({ show, setShow }) => {
  return (
    <div>
      {' '}
      <main className='main'>
        <div className='intro-slider-container mb-3 mb-lg-5'>
          <div
            className='intro-slider owl-carousel owl-simple owl-nav-inside owl-light'
            data-toggle='owl'
            data-owl-options='{"nav":false, "dots": false, "loop": false}'
          >
            <div
              className='intro-slide'
              style={{
                backgroundImage:
                  'url(assets/images/demos/demo-12/slider/slide-1.jpg)',
              }}
            >
              <div className='container intro-content text-center'>
                <h3 className='intro-subtitle text-white'>Annie Sawyer</h3>
                {/* End .h3 intro-subtitle */}
                <h1 className='intro-title text-white'>
                  Bringing you the best shoes
                </h1>
                {/* End .intro-title */}
                <Link to='/category' className='btn btn-outline-white'>
                  <span>DISCOVER MORE</span>
                  <i className='icon-long-arrow-right' />
                </Link>
              </div>
              {/* End .intro-content */}
            </div>
            {/* End .intro-slide */}
          </div>

          <span className='slider-loader text-white' />
        </div>
        {/* End .intro-slider-container */}
        <div className='container'>
          <div className='row'>
            <div className='col-md-6'>
              <div className='banner banner-display banner-link-anim banner-title-hidden'>
                <Link to='/'>
                  <img
                    src='assets/images/demos/demo-12/banners/banner-1.jpg'
                    alt={'Banner'}
                  />
                </Link>
                <div className='banner-content banner-content-center'>
                  <h3 className='banner-title text-white'>
                    <Link to='/'>This Week's Most Wanted</Link>
                  </h3>
                  {/* End .banner-title */}
                  <Link to='/' className='btn btn-outline-white banner-link'>
                    Shop Now
                  </Link>
                </div>
                {/* End .banner-content */}
              </div>
              {/* End .banner */}
            </div>
            {/* End .col-md-6 */}
            <div className='col-md-6'>
              <div className='banner banner-display banner-link-anim banner-title-hidden'>
                <Link to='/'>
                  <img
                    src='assets/images/demos/demo-12/banners/banner-2.jpg'
                    alt={'Banner'}
                  />
                </Link>
                <div className='banner-content banner-content-center'>
                  <h3 className='banner-title text-white'>
                    <Link to='/'>Bags by Mood</Link>
                  </h3>
                  {/* End .banner-title */}
                  <Link to='/' className='btn btn-outline-white banner-link'>
                    Discover Now
                  </Link>
                </div>
                {/* End .banner-content */}
              </div>
              {/* End .banner */}
              <div className='banner banner-display banner-link-anim banner-title-hidden'>
                <Link to='/'>
                  <img
                    src='assets/images/demos/demo-12/banners/banner-3.jpg'
                    alt={'Banner'}
                  />
                </Link>
                <div className='banner-content banner-content-center'>
                  <h3 className='banner-title text-white'>
                    <Link to='/'>The Trend Story</Link>
                  </h3>
                  {/* End .banner-title */}
                  <Link to='/' className='btn btn-outline-white banner-link'>
                    Shop Now
                  </Link>
                </div>
                {/* End .banner-content */}
              </div>
              {/* End .banner */}
            </div>
            {/* End .col-md-6 */}
          </div>
          {/* End .row */}
          <div className='mb-3' />
          {/* End .mb-3 */}
        </div>
        {/* End .container */}
        <div className='bg-lighter pt-5 pb-5 mb-5'>
          <div className='container'>
            <div className='heading text-center mb-4'>
              <h2 className='title'>Recent Arrivals</h2>
              {/* End .title */}
              <p className='title-desc'>the joy of dressing is an art </p>
              {/* End .title-desc */}
            </div>
            {/* End .heading */}
            <div
              className='owl-carousel owl-simple'
              data-toggle='owl'
              data-owl-options='{
                      "nav": false, 
                      "dots": true,
                      "margin": 20,
                      "loop": false,
                      "responsive": {
                          "0": {
                              "items":2
                          },
                          "480": {
                              "items":2
                          },
                          "768": {
                              "items":3
                          },
                          "992": {
                              "items":4
                          },
                          "1200": {
                              "items":4,
                              "nav": true
                          }
                      }
                  }'
            >
              <div className='product product-4'>
                <figure className='product-media'>
                  <Link to='product'>
                    <img
                      // src='assets/images/demos/demo-12/products/product-1.jpg'
                      src='assets/images/products/annie-shoes/brown-shoe-front.png'
                      alt='Product'
                      className='product-image'
                    />
                    <img
                      // src='assets/images/demos/demo-12/products/product-1-2.jpg'
                      src='assets/images/products/annie-shoes/brown-shoe-full.png'
                      alt='Product'
                      className='product-image-hover'
                    />
                  </Link>
                  <div className='product-action-vertical'>
                    <Link
                      to='/'
                      className='btn-product-icon btn-wishlist btn-expandable'
                    >
                      <span>add to wishlist</span>
                    </Link>

                    <div
                      style={{ cursor: 'pointer' }}
                      className='btn-product-icon btn-quickview'
                      title='Quick view'
                      onClick={() => setShow(!show)}
                    >
                      {' '}
                      <span>Quick view</span>
                    </div>

                    <Link
                      to='/'
                      className='btn-product-icon btn-compare'
                      title='Compare'
                    >
                      <span>Compare</span>
                    </Link>
                  </div>
                  {/* End .product-action */}
                  <div className='product-action'>
                    <Link to='/' className='btn-product btn-cart'>
                      <span>add to cart</span>
                    </Link>
                  </div>
                  {/* End .product-action */}
                </figure>
                {/* End .product-media */}
                <div className='product-body'>
                  <div className='product-cat'>
                    <Link to='/'>Footwear</Link>
                  </div>
                  {/* End .product-cat */}
                  <h3 className='product-title'>
                    <Link to='product'>Flat-heels</Link>
                  </h3>
                  {/* End .product-title */}
                  <div className='product-price'>GHS60.00</div>
                  {/* End .product-price */}
                  <div className='product-nav product-nav-dots'>
                    <Link
                      to='/'
                      className='active'
                      style={{ background: '/e5dcb1' }}
                    >
                      <span className='sr-only'>Brown</span>
                    </Link>
                    <Link to='/' style={{ background: '/bacbd8' }}>
                      <span className='sr-only'>Color name</span>
                    </Link>
                  </div>
                  {/* End .product-nav */}
                </div>
                {/* End .product-body */}
              </div>
              {/* End .product */}
              <div className='product product-4'>
                <figure className='product-media'>
                  <span className='product-label label-primary'>Sale</span>
                  <Link to='product.html'>
                    <img
                      src='assets/images/products/annie-shoes/heel/heel-green.jpeg'
                      alt='Product'
                      className='product-image'
                    />
                    <img
                      src='assets/images/products/annie-shoes/heel/heel-red.jpeg'
                      alt='Product'
                      className='product-image-hover'
                    />
                  </Link>
                  <div className='product-action-vertical'>
                    <Link
                      to='/'
                      className='btn-product-icon btn-wishlist btn-expandable'
                    >
                      <span>add to wishlist</span>
                    </Link>
                    <div
                      style={{ cursor: 'pointer' }}
                      className='btn-product-icon btn-quickview'
                      title='Quick view'
                      onClick={() => setShow(!show)}
                    >
                      {' '}
                      <span>Quick view</span>
                    </div>
                    <Link
                      to='/'
                      className='btn-product-icon btn-compare'
                      title='Compare'
                    >
                      <span>Compare</span>
                    </Link>
                  </div>
                  {/* End .product-action */}
                  <div className='product-action'>
                    <Link to='/' className='btn-product btn-cart'>
                      <span>add to cart</span>
                    </Link>
                  </div>
                  {/* End .product-action */}
                </figure>
                {/* End .product-media */}
                <div className='product-body'>
                  <div className='product-cat'>
                    <Link to='/'>Shoes</Link>
                  </div>
                  {/* End .product-cat */}
                  <h3 className='product-title'>
                    <Link to='product.html'>2-inch heels</Link>
                  </h3>
                  {/* End .product-title */}
                  <div className='product-price'>
                    <span className='new-price'>Now GHS70.00</span>
                    <span className='old-price'>Was GHS84.00</span>
                  </div>
                  {/* End .product-price */}
                </div>
                {/* End .product-body */}
              </div>
              {/* End .product */}
              <div className='product product-4'>
                <figure className='product-media'>
                  <Link to='product.html'>
                    <img
                      src='assets/images/demos/demo-12/products/product-3.jpg'
                      alt='Product '
                      className='product-image'
                    />
                    <img
                      src='assets/images/demos/demo-12/products/product-3-2.jpg'
                      alt='Product '
                      className='product-image-hover'
                    />
                  </Link>
                  <div className='product-action-vertical'>
                    <Link
                      to='/'
                      className='btn-product-icon btn-wishlist btn-expandable'
                    >
                      <span>add to wishlist</span>
                    </Link>
                    <div
                      style={{ cursor: 'pointer' }}
                      className='btn-product-icon btn-quickview'
                      title='Quick view'
                      onClick={() => setShow(!show)}
                    >
                      {' '}
                      <span>Quick view</span>
                    </div>
                    <Link
                      to='/'
                      className='btn-product-icon btn-compare'
                      title='Compare'
                    >
                      <span>Compare</span>
                    </Link>
                  </div>
                  {/* End .product-action */}
                  <div className='product-action'>
                    <Link to='/' className='btn-product btn-cart'>
                      <span>add to cart</span>
                    </Link>
                  </div>
                  {/* End .product-action */}
                </figure>
                {/* End .product-media */}
                <div className='product-body'>
                  <div className='product-cat'>
                    <Link to='/'>Clothing</Link>
                  </div>
                  {/* End .product-cat */}
                  <h3 className='product-title'>
                    <Link to='product.html'>Paper bag trousers</Link>
                  </h3>
                  {/* End .product-title */}
                  <div className='product-price'>GHS60.00</div>
                  {/* End .product-price */}
                  <div className='product-nav product-nav-dots'>
                    <Link
                      to='/'
                      className='active'
                      style={{ background: '/9fac76' }}
                    >
                      <span className='sr-only'>Color name</span>
                    </Link>
                    <Link to='/' style={{ background: '/333333' }}>
                      <span className='sr-only'>Color name</span>
                    </Link>
                  </div>
                  {/* End .product-nav */}
                </div>
                {/* End .product-body */}
              </div>
              {/* End .product */}
              <div className='product product-4'>
                <figure className='product-media'>
                  <Link to='product.html'>
                    <img
                      src='assets/images/products/annie-shoes/rainbow-heel/rainbow-heel-red.jpeg'
                      alt='Product '
                      className='product-image'
                    />
                    <img
                      src='assets/images/products/annie-shoes/rainbow-heel/rainbow-heel-green.jpeg'
                      alt='Product '
                      className='product-image-hover'
                    />
                  </Link>
                  <div className='product-action-vertical'>
                    <Link
                      to='/'
                      className='btn-product-icon btn-wishlist btn-expandable'
                    >
                      <span>add to wishlist</span>
                    </Link>
                    <div
                      style={{ cursor: 'pointer' }}
                      className='btn-product-icon btn-quickview'
                      title='Quick view'
                      onClick={() => setShow(!show)}
                    >
                      {' '}
                      <span>Quick view</span>
                    </div>
                    <Link
                      to='/'
                      className='btn-product-icon btn-compare'
                      title='Compare'
                    >
                      <span>Compare</span>
                    </Link>
                  </div>
                  {/* End .product-action */}
                  <div className='product-action'>
                    <Link to='/' className='btn-product btn-cart'>
                      <span>add to cart</span>
                    </Link>
                  </div>
                  {/* End .product-action */}
                </figure>
                {/* End .product-media */}
                <div className='product-body'>
                  <div className='product-cat'>
                    <Link to='/'>Handbags</Link>
                  </div>
                  {/* End .product-cat */}
                  <h3 className='product-title'>
                    <Link to='product.html'>Bucket bag</Link>
                  </h3>
                  {/* End .product-title */}
                  <div className='product-price'>GHS350.00</div>
                  {/* End .product-price */}
                </div>
                {/* End .product-body */}
              </div>
              {/* End .product */}
              <div className='product product-4'>
                <figure className='product-media'>
                  <Link to='product.html'>
                    <img
                      src='assets/images/demos/demo-12/products/product-5.jpg'
                      alt='Product '
                      className='product-image'
                    />
                    <img
                      src='assets/images/demos/demo-12/products/product-5-2.jpg'
                      alt='Product '
                      className='product-image-hover'
                    />
                  </Link>
                  <div className='product-action-vertical'>
                    <Link
                      to='/'
                      className='btn-product-icon btn-wishlist btn-expandable'
                    >
                      <span>add to wishlist</span>
                    </Link>
                    <div
                      style={{ cursor: 'pointer' }}
                      className='btn-product-icon btn-quickview'
                      title='Quick view'
                      onClick={() => setShow(!show)}
                    >
                      {' '}
                      <span>Quick view</span>
                    </div>
                    <Link
                      to='/'
                      className='btn-product-icon btn-compare'
                      title='Compare'
                    >
                      <span>Compare</span>
                    </Link>
                  </div>
                  {/* End .product-action */}
                  <div className='product-action'>
                    <Link to='/' className='btn-product btn-cart'>
                      <span>add to cart</span>
                    </Link>
                  </div>
                  {/* End .product-action */}
                </figure>
                {/* End .product-media */}
                <div className='product-body'>
                  <div className='product-cat'>
                    <Link to='/'>Clothing</Link>
                  </div>
                  {/* End .product-cat */}
                  <h3 className='product-title'>
                    <Link to='product.html'>Silk-blend kaftan</Link>
                  </h3>
                  {/* End .product-title */}
                  <div className='product-price'>$370.00</div>
                  {/* End .product-price */}
                </div>
                {/* End .product-body */}
              </div>
              {/* End .product */}
            </div>
            {/* End .owl-carousel */}
          </div>
          {/* End .container */}
        </div>
        {/* End .bg-lighter pt-5 pb-5 */}

        {/* End .container */}
      </main>
    </div>
  )
}

export default HomeBody
