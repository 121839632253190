import React from 'react'
import { Link } from 'react-router-dom'

const BigFooter = () => {
  return (
    <div>
      {' '}
      <footer className='footer'>
        <div className='footer-middle'>
          <div className='container'>
            <div className='row'>
              <div className='col-sm-6 col-lg-3'>
                <div className='widget widget-about'>
                  Annie Sawyer Logo
                  {/* <img
                    src='assets/images/logo.png'
                    className='footer-logo'
                    alt='Footer Logo'
                    width='{105}'
                    height='{25}'
                  /> */}
                  <p>Providing you with the best and latest footwear</p>
                  <div className='social-icons'>
                    <a
                      href='www.facebook.com'
                      className='social-icon'
                      target='_blank'
                      title='Facebook'
                    >
                      <i className='icon-facebook-f' />
                    </a>
                    <a
                      href='www.twitter.com'
                      className='social-icon'
                      target='_blank'
                      title='Twitter'
                    >
                      <i className='icon-twitter' />
                    </a>
                    <a
                      href='www.instagram.com'
                      className='social-icon'
                      target='_blank'
                      title='Instagram'
                    >
                      <i className='icon-instagram' />
                    </a>
                  </div>
                  {/* End .soial-icons */}
                </div>
                {/* End .widget about-widget */}
              </div>
              {/* End .col-sm-6 col-lg-3 */}
              <div className='col-sm-6 col-lg-3'>
                <div className='widget'>
                  <h4 className='widget-title'>Useful Links</h4>
                  {/* End .widget-title */}
                  <ul className='widget-list'>
                    <li>
                      <Link to='/about'>About AnnieSawyer</Link>
                    </li>
                    <li>
                      <Link to='/faq'>FAQ</Link>
                    </li>
                    <li>
                      <Link to='/contact'>Contact Us</Link>
                    </li>
                    <li>
                      <Link to='/login'>Login</Link>
                    </li>
                  </ul>
                  {/* End .widget-list */}
                </div>
                {/* End .widget */}
              </div>
              {/* End .col-sm-6 col-lg-3 */}
              <div className='col-sm-6 col-lg-3'>
                <div className='widget'>
                  <h4 className='widget-title'>Customer Service</h4>
                  {/* End .widget-title */}
                  <ul className='widget-list'>
                    <li>
                      <a href='www.anniesawyer.com'>Payment Methods</a>
                    </li>
                    <li>
                      <a href='www.anniesawyer.com'>Money-back guarantee!</a>
                    </li>
                    <li>
                      <a href='www.anniesawyer.com'>Returns</a>
                    </li>
                    <li>
                      <a href='www.anniesawyer.com'>Shipping</a>
                    </li>
                    <li>
                      <a href='www.anniesawyer.com'>Terms and conditions</a>
                    </li>
                    <li>
                      <a href='www.anniesawyer.com'>Privacy Policy</a>
                    </li>
                  </ul>
                  {/* End .widget-list */}
                </div>
                {/* End .widget */}
              </div>
              {/* End .col-sm-6 col-lg-3 */}
              <div className='col-sm-6 col-lg-3'>
                <div className='widget'>
                  <h4 className='widget-title'>My Account</h4>
                  {/* End .widget-title */}
                  <ul className='widget-list'>
                    <li>
                      <Link to='/login'>Sign In</Link>
                    </li>
                    <li>
                      <Link to='/cart'>View Cart</Link>
                    </li>
                    <li>
                      <Link to='/wishlist'>My Wishlist</Link>
                    </li>
                    <li>
                      <Link to='/order'>Track My Order</Link>
                    </li>
                    <li>
                      <Link to='/faq'>Help</Link>
                    </li>
                  </ul>
                  {/* End .widget-list */}
                </div>
                {/* End .widget */}
              </div>
              {/* End .col-sm-6 col-lg-3 */}
            </div>
            {/* End .row */}
          </div>
          {/* End .container */}
        </div>
        {/* End .footer-middle */}
        <div className='footer-bottom'>
          <div className='container'>
            <p className='footer-copyright'>
              Copyright © Annie Sawyer. 2022 - {new Date().getFullYear()} All
              Rights Reserved.
            </p>
            {/* End .footer-copyright */}
            <figure className='footer-payments'>
              <img
                src='assets/images/payments.png'
                alt='Payment methods'
                width='{272}'
                height='{20}'
              />
            </figure>
            {/* End .footer-payments */}
          </div>
          {/* End .container */}
        </div>
        {/* End .footer-bottom */}
      </footer>
    </div>
  )
}

export default BigFooter
