import React from 'react'

const QuickViewHome = ({ setShow }) => {
  return (
    <>
      <div style={styles.modal}></div>
      <div style={styles.wrapper}>
        <p
          onClick={() => setShow(false)}
          style={{
            textAlign: 'right',
            cursor: 'pointer',
            marginBottom: 10,
            color: 'white',
          }}
        >
          X
        </p>
        <div style={styles.product}>
          <img
            src='assets/images/products/product-3.jpg'
            alt='Product '
            className='product-image'
          />
        </div>
      </div>
    </>
  )
}

export default QuickViewHome

const styles = {
  modal: {
    position: 'fixed',
    zIndex: 10,
    backgroundColor: 'black',
    height: '100%',
    width: '100%',
    opacity: 0.72,
  },
  wrapper: {
    position: 'absolute',
    zIndex: 1000,
    top: '170%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // backgroundColor: 'red',
  },
  product: {
    position: 'relative',
    zIndex: 100,
  },
}
